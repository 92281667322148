.header {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 40px;

  background-color: $color-default-black;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  a,
  p {
    color: $color-default-white;
  }

  ul {
    display: flex;
    margin: 0;
    margin-right: 20px;
    margin-left: 60px;
    padding: 0;

    list-style: none;

    li {
      margin-right: 20px;
    }
  }
}

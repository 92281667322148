.btn-back {
  position: fixed;
  top: 90px;
  left: 5.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 108px;
  height: 44px;
  padding: 15px 18px 15px 14px;

  font-family: $font-family--gotham;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;

  color: $color-comet;

  transition: background-color $trans--300, color $trans--300, border-color $trans--300;

  &::before {
    z-index: -1;
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $color-snow;
    border-radius: 100px;
    border: 1px solid $color-light-grey;
    transition: transform $trans--300;
  }

  svg {
    fill: $color-transparent;
    transform: rotate(90deg);
  }

  @include vp--1023 {
    top: 40px;
    left: 24px;

    width: 80px;
    height: 38px;
    padding: 14px 13px 14px 12px;

    font-size: 10px;
    line-height: 10px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @include vp--767 {
    left: 16px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      &::before {
        transform: scale(1.1);
      }
    }
  }
}

.btn-down {
  position: fixed;
  bottom: 0;
  left: 50vw;
  margin-left: size(-100px);

  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(50%);

  padding-top: size(47px);
  width: size(200px);
  height: size(200px);

  border: none;
  border-radius: 50%;
  background-color: $color-default-white;
  cursor: pointer;

  color: $color-comet;
  transition: left $trans--300, max-width $trans--300, max-height $trans--300, opacity $trans--1000, transform $trans--300, background-color $trans--300;

  &::before {
    z-index: -1;
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $color-default-white;
    border-radius: 50%;
    transition: transform $trans--300;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      &::before {
        transform: scale(1.1);
      }
    }
  }

  &__text {
    margin-bottom: size(4px);

    font-family: $font-family--gotham;
    font-weight: 700;
    font-size: size(14px);
    line-height: 1;
    text-transform: uppercase;
  }

  svg {
    width: size(18px);
    height: size(17px);

    fill: $color-transparent;
  }

  @include vp--1023 {
    margin-left: 0;

    width: 95px;
    height: 95px;
    padding-top: 26px;

    left: calc(50vw - 47px);

    transform: translateY(37px);

    //clip: rect(0, 95px, 95px, 0);

    &__text {
      margin-bottom: size(4px);

      font-size: 10px;
    }

    svg {
      width: 11px;
      height: 11px;
    }
  }

  @include vp--767 {

  }
}

.main-page {
  height: 100vh;
  /* stylelint-disable-next-line */
  height: calc(100 * var(--vh, 1vh));
  min-height: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: size(65px);
  position: relative;

  &::after {
    z-index: 20;
    position: fixed;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(129.5deg, $color-summer-sky 25.85%, $color-fun-blue 100%);
  }

  &.animate-load,
  &.animate-load-end {
    &::after {
      display: none;
    }
  }

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      padding-top: 60px;
    }
  }

  @include vp--1023 {
    padding-top: 52px;
  }

  @include vp--767 {
    padding-top: 39px;
  }

  [data-device="mobile landscape"] & {
    padding-top: 16px;
  }
}

.main-page__title {
  position: relative;
  z-index: 1;
}

.main-page__splash {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  transition: opacity 1s ease;

  .animate-load &.active {
    opacity: 1;
  }
}

.main-page__bottom {
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-page__btn-down {
  position: relative;
  left: auto;
  bottom: auto;
  top: auto;
  margin-bottom: -50%;
  margin-left: 0;
  transform: translate3d(0, 100%, 0);

  .animate-load & {
    animation-name: fade-in-bottom;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-delay: 1.3s;
  }

  .animate-load-end & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      width: 180px;
      height: 180px;

      padding-top: 37px;

      .btn-down__text {
        margin-bottom: 4px;
        font-size: 14px;
      }

      svg {
        width: 18px;
        height: 17px;
      }
    }
  }

  @include vp--1023 {
    left: auto;
    top: auto;
    bottom: auto;
    transform: translate3d(0, 100%, 0);
    width: 150px;
    height: 150px;
    padding-top: 36px;

    .btn-down__text {
      font-size: 12px;
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }

  @include vp--767 {
    left: auto;
    top: auto;
    bottom: auto;
    transform: translate3d(0, 100%, 0);
    margin-bottom: -39%;
    width: 95px;
    height: 95px;
    padding-top: 26px;

    .btn-down__text {
      font-size: 10px;
    }

    svg {
      width: 11px;
      height: 11px;
    }
  }
}

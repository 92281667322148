.slider-btn {
  top: 49%;
  width: size(60px);
  height: size(60px);

  &::before,
  &::after {
    display: none;
  }

  svg {
    fill: transparent;
    width: 100%;
    height: 100%;
  }

  transition: transform $trans--300, opacity $trans--300, visibility $trans--300;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      transform: scale(1.2667);
    }
  }

  @include vp--1439 {
    top: 50%;
    width: 54px;
    height: 54px;
  }

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      width: 60px;
      height: 60px;
    }
  }

  @include vp--1279 {
    width: 48px;
    height: 48px;
  }

  @include vp--767 {
    top: 52%;
    width: 37px;
    height: 37px;
  }
}

.slider-btn--prev {
  left: 21.8%;

  @include vp--1439 {
    left: 12%;
  }

  @include vp--767 {
    left: 5%;
  }
}

.slider-btn--next {
  right: 21.8%;

  svg {
    transform: rotate(180deg);
  }

  @include vp--1439 {
    right: 12%;
  }

  @include vp--767 {
    right: 5%;
  }
}

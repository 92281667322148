@keyframes grow-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-step {
  0% {
    opacity: 0;
    transform: translateY(300%);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-200 {
  from {
    opacity: 0;
    transform: translateY(200%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes hide-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes show-from-top {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

//slider load
@keyframes ice-up {
  from {
    transform: translate3d(0, 100vh, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

// slide animations

// slide next
@keyframes bottle-next-to-active {
  from {
    transform: translate3d(50vw, 0, 0) scale(0.83);
    filter: blur(4px);
  }

  to {
    transform: translate3d(0, 0, 0);
    filter: blur(0);
  }
}

@keyframes bottle-next-to-active-mobile {
  from {
    transform: translate3d(100vw, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bg-next-to-active {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes decor-next-to-active {
  from {
    transform: translate3d(110vw, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bottle-active-to-prev {
  from {
    transform: translate3d(0, 0, 0);
    filter: blur(0);
  }

  to {
    transform: translate3d(-50vw, 0, 0) scale(0.83);
    filter: blur(4px);
  }
}

@keyframes bottle-active-to-prev-mobile {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100vw, 0, 0);
  }
}

@keyframes decor-active-to-prev {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-110vw, 0, 0);
  }
}

@keyframes bg-active-to-prev {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes bottle-prev-to-hidden {
  from {
    transform: translate3d(-50vw, 0, 0) scale(0.83);
  }

  to {
    transform: translate3d(-70vw, 0, 0) scale(0.83);
  }
}

@keyframes bottle-hidden-to-next {
  from {
    transform: translateX(100vw) scale(0.83);
  }

  to {
    transform: translate3d(50vw, 0, 0) scale(0.83);
  }
}


//slide prev

@keyframes bottle-prev-to-active {
  from {
    transform: translate3d(-50vw, 0, 0) scale(0.83);
    filter: blur(4px);
  }

  to {
    transform: translate3d(0, 0, 0);
    filter: blur(0);
  }
}

@keyframes bottle-prev-to-active-mobile {
  from {
    transform: translate3d(-100vw, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bg-prev-to-active {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes decor-prev-to-active {
  from {
    transform: translate3d(-110vw, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}


@keyframes bottle-active-to-next {
  from {
    transform: translate3d(0, 0, 0);
    filter: blur(0);
  }

  to {
    transform: translate3d(50vw, 0, 0) scale(0.83);
    filter: blur(4px);
  }
}

@keyframes bottle-active-to-next-mobile {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100vw, 0, 0);
  }
}

@keyframes decor-active-to-next {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(110vw, 0, 0);
  }
}

@keyframes bg-active-to-next {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes bottle-hidden-to-prev {
  from {
    transform: translate3d(-70vw, 0, 0) scale(0.83);
  }

  to {
    transform: translate3d(-50vw, 0, 0) scale(0.83);
  }
}

@keyframes bottle-next-to-hidden {
  from {
    transform: translate3d(50vw, 0, 0) scale(0.83);
  }

  to {
    transform: translate3d(70vw, 0, 0) scale(0.83);
  }
}



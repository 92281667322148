.tastes-title {
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: size(40px);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;

  //@include vp--1919 {
  //  margin-bottom: 30px;
  //}

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      margin-bottom: 30px;
    }
  }

  @include vp--1023 {
    margin-bottom: 30px;
  }

  @include vp--767 {
    margin-bottom: 20px;
  }

  [data-device="mobile landscape"] & {
    margin-bottom: 12px;
  }
}

.tastes-title__text {
  will-change: transform;
  margin: 0;
  transform: translate3d(0, 100%, 0);
  position: relative;
  padding-bottom: size(23px);
  min-width: size(500px);

  .animate-load & {
    animation-name: grow-up;
    animation-duration: 1s;
    animation-delay: 1.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    //@include vp--767 {
    //  animation-delay: 1.3s;
    //  animation-duration: 0.6s;
    //}
  }

  @include vp--1919 {
    padding-bottom: 23px;
    min-width: 500px;
  }

  .animate-load-end & {
    transform: translate3d(0, 0, 0);
  }
}

.tastes-title__brand {
  display: block;
  margin-bottom: size(7px);
  font-family: $font-family--gotham;
  font-style: italic;
  font-weight: 900;
  font-size: size(40px);
  line-height: 1;

  //@include vp--1919 {
  //  font-size: 36px;
  //}
  //
  //@include vp--1279 {
  //  font-size: 32px;
  //}


  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      font-size: 32px;
    }
  }

  @include vp--1023 {
    font-size: 32px;
  }

  @include vp--767 {
    margin-bottom: 0;
    font-size: 22px;
  }
}

.tastes-title__subtitle-wrapper {
  display: block;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  height: size(23px);
  width: 100%;
  text-align: center;

  @include vp--1919 {
    height: 22px;
  }

  @include vp--767 {
    height: 16px;
  }
}

.tastes-title__subtitle {
  display: block;
  position: absolute;
  top: 0;
  font-family: $font-family--helvetica;
  font-weight: 500;
  font-size: size(18px);
  line-height: 1.3;
  letter-spacing: 0.3em;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  white-space: nowrap;

  &.hiding {
    opacity: 1;
    visibility: visible;
    animation-name: hide-up;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    animation-name: show-from-top;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  //@include vp--1919 {
  //  font-size: 17px;
  //}
  //
  //@include vp--1279 {
  //  font-size: 16px;
  //}

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      font-size: 16px;
    }
  }

  @include vp--1023 {
    font-size: 16px;
  }

  @include vp--767 {
    font-size: 12px;
  }
}

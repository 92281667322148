.tastes {
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //width: 100%;
  //height: 100vh;
  /* stylelint-disable-next-line */
  //height: calc(100 * var(--vh, 1vh));
}



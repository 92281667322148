.preloader {
  position: fixed;
  z-index: 1000;
  left: -25%;
  top: -90%;

  width: 150%;
  height: 150%;
  border-radius: 0 0 60% 60%;
  will-change: transform;
  transform: scale(4);

  background-color: $color-default-white;

  @include vp--1023 {
    left: -80%;
    width: 260%;
  }

  @include vp--767 {
    left: -150%;
    width: 400%;
  }
}

.preloader.loaded {
  transition: top $trans--1000, transform $trans--2000;
  transform: scale(0) translateY(-30%);
}

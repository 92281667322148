.redirect-screen {
  will-change: transform;
  position: fixed;
  z-index: 1000;
  left: -25%;
  bottom: -90%;

  width: 150%;
  height: 150%;
  border-radius: 60% 60% 0 0;
  transform: scale(0) translateY(30%);

  background-color: $color-default-white;

  transition-delay: 0.1s;

  @include vp--1023 {
    left: -80%;
    width: 260%;
  }

  @include vp--767 {
    left: -150%;

    width: 400%;
  }
}

.redirect-screen--show {
  transition: top $trans--1000, transform $trans--2000;
  bottom: -77%;
  transform: scale(4) translateY(0);
}

.taste {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: size(144px) 10% size(148px);
  overflow: hidden;
  //padding: size(160px) 10% size(238px);
  user-select: none;

  @include vp--1919 {
    padding: 136px 10% 180px;
  }

  @include vp--1279 {
    //padding: 126px 10% 162px;
    padding: 126px 10% 162px;
  }

  @include vp--1023 {
    padding: 126px 10% 200px;
  }

  @include vp--767 {
    //padding: 88px 4% 80px;
    padding: 100px 4% 128px;
  }
}


.taste__bg,
.taste__pattern {
  position: absolute;
  top: -5%;
  right: -8%;
  bottom: -5%;
  left: -8%;

  @include vp--1919 {
    right: -12%;
    left: -12%;
  }

  @include vp--767 {
    right: -15%;
    left: -15%;
  }
}

.taste__pattern {
  opacity: 0;
  transition: opacity 1s ease;
}

.taste__splash {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $vp--1920) {
    img {
      width: size(1000px);
      height: size(1000px);
    }
  }


  @include vp--1919 {
    img {
      max-width: 600px;
    }
  }

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      img {
        max-width: 100%;
      }
    }
  }

  @include vp--1023 {
    img {
      max-width: 800px;
    }
  }

  @include vp--767 {
    width: 123%;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 0);

    img {
      max-width: 100%;
    }
  }

  [data-device="mobile landscape"] & {
    width: 70%;
  }
}

.taste__bottle {
  z-index: 1;
  position: absolute;
  transition: filter $trans--1000;

  left: 33.6%;
  top: size(144px);
  bottom: size(148px);

  width: 30.7%;

  img {
    will-change: transform, filter;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  [data-device="tablet portrait"] & {
    width: 70%;
    left: 13%;
  }

  [data-device="tablet landscape"] & {
    left: 28.6%;
    width: 40%;
  }

  @include vp--1023 {
    top: 120px;
    left: 12.6%;
    bottom: 158px;
    width: 70vw;
  }

  @include vp--767 {
    left: 1.2vw;
    top: 14.8vh;

    width: 91vw;
    height: 62.8vh;
    height: calc(71.8*var(--vh, 1vh));
  }

  [data-device="mobile landscape"] & {
    width: 30%;
    left: 33.6%;
  }
}

.taste__decor-img {
  position: absolute;
  z-index: 1;
  opacity: 0;

  @media (min-width: $vp--1920) {
    img {
      width: size(250px);
      height: size(250px);
    }
  }

  @include vp--1919 {
    width: 180px;
  }

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      width: 240px;
    }
  }

  @include vp--1023 {
    width: 160px;
  }

  @include vp--767 {
    width: 37.5%;
    max-width: 140px;
  }

  [data-device="mobile landscape"] & {
    width: 100px;
    height: 100px;
  }
}

.taste--active,
.taste--prev,
.taste--next {
  display: flex;

  .taste__decor-img {
    opacity: 1;
  }
}

.taste--prev {
  .taste__bottle img {
    @media (min-width: $vp--768) {
      filter: blur(4px);
      transform: translate3d(-50vw, 0, 0) scale(0.83);
    }

    @include vp--767 {
      transform: translate3d(-100vw, 0, 0);
    }
  }

  .taste__bg {
    transform: translate3d(-100%, 0, 0);
  }

  .taste__decor-img img {
    transform: translate3d(-110vw, 0, 0);
  }
}

.taste--active {
  .taste__splash,
  .taste__pattern,
  .taste__decor-img {
    opacity: 1;
  }

  .taste__decor-img img {
    transform: translate3d(0, 100vh, 0);
  }
}

.taste--next {
  .taste__bg {
    transform: translate3d(100%, 0, 0);
  }

  .taste__decor-img img {
    transform: translate3d(110vw, 0, 0);
  }

  .taste__bottle img {
    @media (min-width: $vp--768) {
      filter: blur(4px);
      transform: translate3d(50vw, 0, 0) scale(0.83);
    }

    @include vp--767 {
      transform: translate3d(100vw, 0, 0);
    }
  }
}

.taste--hiding {
  .taste__pattern,
  .taste__bg,
  .taste__decor-img {
    display: none;
  }
}

.taste--hidden {
  z-index: -1;
}

.animate-load {
  .taste {
    &:first-child {
      z-index: auto;
    }
  }

  .taste--active {
    .taste__decor-img img {
      animation-name: ice-up;
      animation-duration: 1s;
      animation-delay: 1.3s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
    }
  }
}

.animate-load-end,
.slide-end {
  .taste--active {
    .taste__decor-img img {
      transform: translate3d(0, 0, 0);
    }
  }

  .taste--hiding {
    z-index: 0;
  }
}

.slide-end {
  .taste--prev {
    .taste__bottle img {
      @media (min-width: $vp--768) {
        filter: blur(4px);
        transform: translate3d(-50vw, 0, 0) scale(0.83);
      }

      @include vp--767 {
        transform: translate3d(-100vw, 0, 0);
      }
    }

    .taste__bg {
      transform: translate3d(-100%, 0, 0);
    }

    .taste__decor-img img {
      transform: translate3d(-110vw, 0, 0);
    }
  }

  .taste--next {
    .taste__bg {
      transform: translate3d(100%, 0, 0);
    }

    .taste__decor-img img {
      transform: translate3d(110vw, 0, 0);
    }

    .taste__bottle img {
      @media (min-width: $vp--768) {
        filter: blur(4px);
        transform: translate3d(50vw, 0, 0) scale(0.83);
      }

      @include vp--767 {
        transform: translate3d(100vw, 0, 0);
      }
    }
  }

  .taste--hiding {
    .taste__bottle {
      opacity: 0;
    }
  }
}

.animate-redirect {
  @media (min-width: $vp--768) {
    .taste__bottle img {
      transition: transform $trans--1000;
    }

    .taste--prev {
      .taste__bottle img {
        transform: translate3d(-70vw, 0, 0);
      }
    }

    .taste--next {
      .taste__bottle img {
        transform: translate3d(70vw, 0, 0);
      }
    }
  }

  .taste--active {
    .taste__decor-img {
      transition: top $trans--1000, bottom $trans--1000, transform $trans--1000;

      &:nth-child(1),
      &:nth-child(2) {
        bottom: 100%;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        top: -30%;
      }

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(6) {
        transform: rotate(-45deg);
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        transform: rotate(45deg);
      }
    }

    &.taste--still {
      .taste__decor-img {
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5) {
          top: -30%;
        }

        &:nth-child(2) {
          bottom: 100%;
        }
      }
    }

    &.taste--lemon {
      .taste__decor-img {
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(6) {
          top: -30%;
        }

        &:nth-child(2) {
          bottom: 100%;
        }
      }
    }

    &.taste--raspberry {
      .taste__decor-img {
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          top: -30%;
        }

        &:nth-child(1) {
          bottom: 100%;
        }
      }
    }

    &.taste--soda {
      .taste__decor-img {
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          top: -30%;
        }

        &:nth-child(2) {
          bottom: 100%;
        }
      }
    }
  }
}

.slide-next,
.slide-prev {
  .taste--hiding {
    z-index: 4;
  }

  .taste--prev,
  .taste--next {
    .taste__decor-img {
      transition: transform $trans--1000;
    }
  }
}

.slide-next {
  .taste--active {
    .taste__bg {
      transform: translate3d(100%, 0, 0);
      animation-name: bg-next-to-active;
      animation-duration: 0.8s;
      animation-delay: 0.05s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__pattern {
      animation-name: fade-in;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__splash {
      opacity: 0;
      animation-name: fade-in;
      animation-duration: 1s;
      animation-delay: 0.08s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__decor-img img {
      animation-name: decor-next-to-active;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      @include vp--767 {
        transform: translate3d(110vw, 0, 0);
        animation-delay: 0.1s;
        animation-duration: 0.9s;
      }
    }

    .taste__bottle img {
      animation-name: bottle-next-to-active;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      @include vp--767 {
        animation-name: bottle-next-to-active-mobile;
      }
    }
  }

  .taste--prev {
    .taste__bg {
      transform: translate3d(0, 0, 0);
      animation-name: bg-active-to-prev;
      animation-duration: 1s;
      animation-delay: 0.15s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__pattern {
      animation-name: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__splash {
      opacity: 1;
      animation-name: fade-out;
      animation-duration: 1s;
      animation-delay: 0.08s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__decor-img img {
      opacity: 1;
      animation-name: decor-active-to-prev;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__bottle img {
      animation-name: bottle-active-to-prev;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;


      @include vp--767 {
        animation-name: bottle-active-to-prev-mobile;
      }
    }
  }

  .taste--next {
    .taste__bottle img {
      filter: blur(4px);

      @media (min-width: $vp--768) {
        animation-name: bottle-hidden-to-next;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }
    }
  }

  .taste--hiding {
    .taste__bottle img {
      filter: blur(4px);

      @media (min-width: $vp--768) {
        animation-name: bottle-prev-to-hidden;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }

      @include vp--767 {
        transform: translate3d(-100vw, 0, 0) scale(0.83);
      }
    }
  }
}

.slide-prev {
  .taste--active {
    .taste__bg {
      transform: translate3d(-100%, 0, 0);
      animation-name: bg-prev-to-active;
      animation-duration: 0.8s;
      animation-delay: 0.05s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__pattern {
      animation-name: fade-in;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__splash {
      opacity: 0;
      animation-name: fade-in;
      animation-duration: 1s;
      animation-delay: 0.08s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__decor-img img {
      animation-name: decor-prev-to-active;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      @include vp--767 {
        transform: translate3d(-110vw, 0, 0);
        animation-delay: 0.1s;
        animation-duration: 0.9s;
      }
    }

    .taste__bottle img {
      animation-name: bottle-prev-to-active;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      @include vp--767 {
        animation-name: bottle-prev-to-active-mobile;
      }
    }
  }

  .taste--next {
    .taste__bg {
      transform: translate3d(0, 0, 0);
      animation-name: bg-active-to-next;
      animation-duration: 1s;
      animation-delay: 0.15s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__pattern {
      animation-name: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__splash {
      opacity: 1;
      animation-name: fade-out;
      animation-duration: 1s;
      animation-delay: 0.08s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__decor-img img {
      opacity: 1;
      animation-name: decor-active-to-next;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .taste__bottle img {
      animation-name: bottle-active-to-next;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      @include vp--767 {
        animation-name: bottle-active-to-next-mobile;
      }
    }
  }

  .taste--prev {
    .taste__bottle img {
      filter: blur(4px);

      @media (min-width: $vp--768) {
        animation-name: bottle-hidden-to-prev;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }
    }
  }

  .taste--hiding {
    .taste__bottle img {
      filter: blur(4px);

      @media (min-width: $vp--768) {
        animation-name: bottle-next-to-hidden;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }

      @include vp--767 {
        transform: translate3d(100vw, 0, 0);
      }
    }
  }
}



.taste--still {
  .taste__bg {
    background: linear-gradient(129.5deg, $color-summer-sky 25.85%, $color-fun-blue 100%);
  }

  .taste__pattern {
    background-image: url("../img/bg/still-bg.png");
    background-size: size(324px) size(324px);

    @include retina {
      background-image: url("../img/bg/still-bg@2x.png");
    }

    @include vp--1919 {
      background-size: 280px 280px;
    }

    @include vp--1023 {
      background-size: 200px 200px;
    }

    @include vp--767 {
      background-size: 124px 124px;
    }
  }

  .taste__decor-img {
    &:nth-child(1) {
      right: 61%;
      bottom: 55.6%;
    }

    &:nth-child(2) {
      left: 58.5%;
      bottom: 55.6%;
    }

    &:nth-child(3) {
      left: 63.2%;
      top: 42.4%;
    }

    &:nth-child(4) {
      top: 60.6%;
      left: 56.9%;
    }

    &:nth-child(5) {
      top: 48.6%;
      right: 48%;
    }

    &:nth-child(6) {
      right: 60.9%;
      top: 66.4%;
    }

    @include vp--767 {
      &:nth-child(1) {
        right: 64.6%;
        top: 58.6%;
        bottom: auto;
      }

      &:nth-child(2) {
        left: 57.5%;
        bottom: 60.2%;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        top: 65.1%;
        left: 67.8%;
      }

      &:nth-child(5) {
        top: 48.9%;
        right: auto;
        left: 45.2%;
      }

      &:nth-child(6) {
        display: none;
      }
    }
  }
}

.taste--lemon {
  .taste__bg {
    background: linear-gradient(129.5deg, $color-broom 25.85%, $color-apple 100%);
  }

  .taste__pattern {
    background-image: url("../img/bg/lemon-bg.png");
    background-size: size(475px) size(475px);

    @include retina {
      background-image: url("../img/bg/lemon-bg@2x.png");
    }

    @include vp--1919 {
      background-size: 400px 400px;
    }

    @include vp--1023 {
      background-size: 300px 300px;
    }

    @include vp--767 {
      background-size: 190px 190px;
    }
  }

  //.taste__splash {
  //  top: 48.2%;
  //  left: 50%;
  //}

  .taste__decor-img {
    &:nth-child(1) {
      right: 61%;
      bottom: 55.6%;
    }

    &:nth-child(2) {
      bottom: 55.6%;
      left: 58.5%;
    }

    &:nth-child(3) {
      left: 63.2%;
      top: 42.4%;
    }

    &:nth-child(4) {
      top: 60.6%;
      left: 56.9%;
    }

    &:nth-child(5) {
      top: 48.6%;
      right: 48%;
    }

    &:nth-child(6) {
      right: 60.9%;
      top: 66.3%;
    }

    @include vp--767 {
      &:nth-child(1) {
        right: 64.6%;
        top: 58.6%;
        bottom: auto;
      }

      &:nth-child(2) {
        bottom: 60.3%;
        left: 57.5%;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        top: 49%;
        left: 45.2%;
      }

      &:nth-child(5) {
        display: none;
      }

      &:nth-child(6) {
        top: 65.2%;
        left: 67.7%;
        right: auto;
      }
    }
  }
}

.taste--raspberry {
  .taste__bg {
    background: linear-gradient(129.5deg, $color-cranberry 25.85%, $color-valencia 100%);
  }

  .taste__pattern {
    background-image: url("../img/bg/raspberries-bg.png");
    background-size: size(385px) size(385px);

    @include retina {
      background-image: url("../img/bg/raspberries-bg@2x.png");
    }

    @include vp--1919 {
      background-size: 320px 320px;
    }

    @include vp--1023 {
      background-size: 250px 250px;
    }

    @include vp--767 {
      background-size: 170px 170px;
    }
  }

  //.taste__splash {
  //  top: 49.6%;
  //  left: 50%;
  //}

  .taste__decor-img {
    &:nth-child(1) {
      bottom: 55.6%;
      right: 61%;
    }

    &:nth-child(2) {
      bottom: 55.6%;
      left: 58.5%;
    }

    &:nth-child(3) {
      left: 63.2%;
      top: 42.4%;
    }

    &:nth-child(4) {
      top: 60.6%;
      left: 56.9%;
    }

    &:nth-child(5) {
      right: 48%;
      top: 48.6%;
    }

    &:nth-child(6) {
      top: 66.4%;
      right: 60.9%;
    }

    @include vp--767 {
      &:nth-child(1) {
        right: auto;
        bottom: 60.3%;
        left: 57.5%;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        top: 65.2%;
        left: 67.7%;
      }

      &:nth-child(4) {
        display: none;
      }

      &:nth-child(5) {
        right: auto;
        top: 49%;
        left: 45.2%;
      }

      &:nth-child(6) {
        right: 64.6%;
        top: 58.6%;
      }
    }
  }
}

.taste--soda {
  .taste__bg {
    background: linear-gradient(129.5deg, $color-persian-red 25.85%, $color-mandarian-orange 100%);
  }

  .taste__pattern {
    background-image: url("../img/bg/soda-bg.png");
    background-size: size(174px) size(177px);

    @include retina {
      background-image: url("../img/bg/soda-bg@2x.png");
    }

    @include vp--1919 {
      background-size: 150px 153px;
    }

    @include vp--1023 {
      background-size: 120px 122px;
    }

    @include vp--767 {
      background-size: 100px 101px;
    }
  }

  .taste__decor-img {
    &:nth-child(1) {
      bottom: 55.6%;
      right: 61%;
    }

    &:nth-child(2) {
      left: 58.5%;
      bottom: 55.6%;
    }

    &:nth-child(3) {
      top: 42.4%;
      left: 63.2%;
    }

    &:nth-child(4) {
      top: 60.5%;
      left: 56.9%;
    }


    &:nth-child(5) {
      right: 48%;
      top: 48.6%;
    }

    &:nth-child(6) {
      right: 60.9%;
      top: 66.4%;
    }

    @include vp--767 {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        bottom: 60.3%;
        left: 57.5%;
      }

      &:nth-child(3) {
        top: 65.2%;
        left: 67.7%;
      }

      &:nth-child(4) {
        display: none;
      }

      &:nth-child(5) {
        right: auto;
        top: 45%;
        left: 45.2%;
      }

      &:nth-child(6) {
        right: 64.6%;
        top: 58.6%;
      }
    }
  }
}

.scroll-content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transform: translateY(100%);
  transition: transform $trans--1500;

  @include vp--767 {
    position: relative;
    transform: translateY(0%);
  }

  &__boutle-bg {
    position: absolute;
    top: -11.75vh;
    top: calc(-11.75 * var(--vh, 1vh));
    left: -66.6vh;
    left: calc(-66.6 * var(--vh, 1vh));

    height: 123.5vh;
    height: calc(123.5 * var(--vh, 1vh));
    width: 123.5vh;
    width: calc(123.5 * var(--vh, 1vh));
    max-width: 100vw;

    background-color: $color-default-white;
    border-radius: 50%;

    transition: transform $trans--1000;
    transform: scale(0.6) translate(-50%, 95%);

    @media screen and (max-width: 950px) and (min-height: 850px) {
      left: -60vw;
    }
  }


  .decor-el {
    position: absolute;
  }
}

.scroll-content.is-anim {
  transform: translateY(0);
}

.scroll-content.hidden {
  transform: translateY(-100%);

  @include vp--767 {
    transform: translateY(0);
  }
}

.scroll-content--main {
  @include vp--767 {
    width: 100%;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));

    transform: translateY(100%);
  }

  .full-text {
    position: absolute;

    top: 48.3vh;
    top: calc(48.3 * var(--vh, 1vh) - 8.125rem);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;

    @include vp--767 {
      top: calc(48.3 * var(--vh, 1vh) - 60px);
    }

    &__text {
      margin: 0;

      font-family: $font-family--gotham;
      font-style: italic;
      font-weight: 900;
      line-height: 1;
      font-size: size(130px);
      text-transform: uppercase;
      white-space: nowrap;

      color: $color-default-white;
      transition: transform $trans--2000;

      @include vp--767 {
        font-size: 50px;
      }

      span {
        color: $color-transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: $color-default-white;
      }

      &--first {
        margin-bottom: size(10px);
        margin-left: size(-791px);

        transform: translateX(10%);

        @include vp--767 {
          margin-bottom: 1px;
          margin-left: -413px;
        }
      }

      &--second {
        margin-left: size(-512px);

        transform: translateX(-7%);

        @include vp--767 {
          margin-left: -730px;
        }
      }
    }
  }
}

.scroll-content--main.is-anim {
  .full-text__text {
    transform: translateY(0);
  }
}

.scroll-content--main.hidden {
  .full-text__text--first {
    transform: translateX(-10%);
  }

  .full-text__text--second {
    transform: translateX(7%);
  }
}

.scroll-content--description {
  @include vp--767 {
    padding-top: 13px;
  }

  .description-block.left {
    top: 25vh;
    top: calc(25 * var(--vh, 1vh));
    left: 4.6vw;

    max-width: calc(50vw - 511px);
    min-width: 400px;

    .description-block__sub-title {
      font-style: italic;

      @include vp--767 {
        font-style: normal;
      }
    }

    @include vp--1023 {
      min-width: unset;
      max-width: calc(50vw - 175px);
    }

    @include vp--767 {
      max-width: 100%;
      min-width: unset;
    }
  }

  .description-block.right {
    bottom: 31.3vh;
    bottom: calc(31.3 * var(--vh, 1vh));
    right: 6.8vw;

    max-width: calc(50vw - 441px);
    min-width: 340px;

    @include vp--1023 {
      right: 24px;

      max-width: calc(50vw - 138px);
      min-width: unset;
    }

    @include vp--767 {
      max-width: 100%;
      min-width: unset;
    }
  }

  .decor-el--1 {
    bottom: 11vh;
    bottom: calc(11 * var(--vh, 1vh));
    top: unset;
    right: unset;
    left: 23vw;

    @include vp--767 {
      display: none;
      bottom: 0;
      right: 0;
      left: unset;
    }
  }

  .decor-el--4 {
    display: none;

    transition: transform $trans--2000;
    transform: translateY(100%);

    bottom: unset;
    top: -8.6vh;
    top: calc(-8.6*var(--vh, 1vh));

    @include vp--767 {
      display: block;
      bottom: 49px;
      right: -28px;
      top: unset;
      left: unset;
    }
  }
}

.scroll-content--description.is-anim {
  .decor-el--4 {
    transform: translateY(0);
  }
}

.scroll-content--quality {
  @include vp--767 {
    margin-top: 60px;
    margin-bottom: 90px;
  }

  .description-block {
    right: 89px;
    top: 28.7vh;
    top: calc(28.7 * var(--vh, 1vh));

    @include vp--1439 {
      right: 30px;
    }

    @media screen and (min-height: 750px) and (min-width: 767px) and (max-width: 1320px) {
      transform: translateY(20%);

      @media screen and (min-height: 1200px) {
        transform: translateY(45%);
      }
    }

    .description-block__sub-title {
      @include vp--767 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.275em;
      }
    }

    .description-block__text {
      @include vp--767 {
        margin-top: 22px;

        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  .decor-el--1 {
    right: unset;
    left: 19vw;
    top: 10vh;
    top: calc(10*var(--vh, 1vh));

    @include vp--767 {
      top: unset;
      left: -7px;
      bottom: -131px;
    }
  }

  .decor-el--2 {
    top: unset;
    bottom: calc(-1*var(--vh, 1vh));
    left: 16.5vw;

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--3 {
    left: unset;
    right: 14.7vw;
    top: 5vh;
    top: calc(5*var(--vh, 1vh));

    @media screen and (max-height: 850px) {
      top: 3vh;
      top: calc(3*var(--vh, 1vh));
    }

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--4 {
    right: 28.7vw;
    bottom: 8.5vh;
    bottom: calc(8.5*var(--vh, 1vh));

    @media screen and (max-height: 590px) {
      right: 40vw;
    }

    @media screen and (max-height: 850px) {
      bottom: -1.5vh;
      bottom: calc(-1.5*var(--vh, 1vh));
    }

    @include vp--767 {
      display: none;
    }
  }
}

.scroll-content--slider {
  @include vp--767 {
    margin-top: 53px;

    display: flex;
    flex-direction: column;
  }

  .description-block.left {
    z-index: 15;
    top: 2.9vh;
    top: calc(2.9 * var(--vh, 1vh));
    left: calc(4.7vw + 150px);

    @include vp--1023 {
      left: 130px;
    }

    @media screen and (min-width: 767px) and (max-height: ) {

    }

    h2 {
      font-size: size(100px);
      line-height: 1;

      @include vp--1023 {
        font-size: size(80px);
      }

      @include vp--767 {
        font-size: 50px;
      }
    }
  }

  .description-block.right {
    bottom: 8.2vh;
    bottom: calc(8.2 * var(--vh, 1vh));
    right: 97px;
    z-index: 15;

    max-width: calc(50vw - 407px);
    min-width: 505px;

    @include vp--1023 {
      right: 24px;

      max-width: calc(50vw - 130px);
      min-width: unset;
    }

    @media screen and (min-width: 1300px) and (max-height: 800px) {
      right: 40px;
      bottom: 30px;

      h2 {
        margin-bottom: 15px;
      }
    }

    @include vp--767 {
      order: 3;
      margin-top: 59px;

      max-width: 100%;
      min-width: unset;
    }

    h2 {
      margin-bottom: size(42px);

      font-size: size(40px);
      line-height: 1;

      @include vp--767 {
        margin-bottom: 20px;

        width: 170px;
        font-size: 22px;
      }
    }

    p {
      margin: 0;
    }
  }

  .scroll-content__slider {
    margin-top: 28vh;
    margin-top: calc(28 * var(--vh, 1vh));

    @include vp--767 {
      order: 2;
      margin-top: 26px;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide {
      width: 411px;
      max-height: 42.87vh;
      max-height: calc(42.87 * var(--vh, 1vh));

      @include vp--1023 {
        width: 301px;
      }

      img {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
      }

      @media screen and (min-width: 1300px) and (max-height: 700px) {
        max-height: 39vh;
        max-height: calc(39*var(--vh, 1vh));
      }

      @include vp--767 {
        width: 174px;
      }

      &:first-child {
        width: 325px;
        padding-left: 24px;

        @include vp--767 {
          width: 190px;
          padding-left: 16px;
        }
      }

      &:last-child {
        width: 325px;
        padding-right: 24px;

        @include vp--767 {
          width: 190px;
          padding-right: 16px;
        }
      }
    }
  }

  .decor-el--1 {
    left: 12.6vw;
    top: 23vh;
    top: calc(23*var(--vh, 1vh));

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--2 {
    right: 15vw;
    left: unset;
    top: 19.5vh;
    top: calc(19.5*var(--vh, 1vh));

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--3 {
    bottom: 21.5vh;
    bottom: calc(21.5*var(--vh, 1vh));
    right: 32.5vw;
    left: unset;

    @include vp--767 {
      display: none;
    }
  }
}

.scroll-content--taste {
  @include vp--767 {
    width: 100%;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));
  }

  .scroll-content__boutle {
    position: fixed;
    z-index: 1;

    left: calc(50% - 16.4vw);
    top: 15vh;
    top: calc(15 * var(--vh, 1vh));

    display: flex;
    align-items: center;

    width: 30.7vw;
    height: 72.8vh;
    height: calc(72.8 * var(--vh, 1vh));

    transform: scale(1.01);
    transition: transform $trans--1300, left $trans--1300, top $trans--1000;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;

    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include vp--767 {
      position: absolute;

      left: calc(50% - 47.3vw);
      top: 7.5vh;
      top: calc(7.5*var(--vh, 1vh));

      height: calc(72.8 * var(--vh, 1vh));
      width: 91.7vw;
    }

    @media screen and (max-height: 400px) {
      top: 3vh;
      top: calc(3*var(--vh, 1vh));
    }

    @media screen and (min-width: 767px) and (min-height: 900px) and (max-width: 1050px) {
      top: 12vh;
      top: calc(12 * var(--vh, 1vh));
      left: 21.5vw;
      width: 52vw;
    }

    @media screen and (max-height: 300px) {
      height: 65.8vh;
      height: calc(65.8 * var(--vh, 1vh));
    }
  }

  .scroll-content__splash {
    position: fixed;
    z-index: 1;

    left: calc(50% - 31.4vw);
    top: -1vh;
    top: calc(-1 * var(--vh, 1vh));

    width: 62.7vw;
    height: 92.8vh;
    height: calc(92.8 * var(--vh, 1vh));

    background-image: url('../img/content/splash-lemon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    transform: scale(1);
    transition: transform $trans--1300, left $trans--1300, top $trans--1000;

    @include retina {
      background-image: url("../img/content/splash-lemon@2x.png");
    }

    @include vp--767 {
      position: absolute;

      left: calc(50% - 68vw);
      top: -5.5vh;
      top: calc(-5.5*var(--vh, 1vh));

      width: 136.7vw;
      height: 106vh;
      height: calc(106*var(--vh, 1vh));
    }

    @media screen and (max-height: 400px) {
      top: -13vh;
      top: calc(-13*var(--vh, 1vh));
    }

    @media screen and (max-height: 300px) {
      left: calc(50% - 70.4vw);

      height: 85.8vh;
      height: calc(85.8 * var(--vh, 1vh));
    }
  }

  .scroll-content__title-wrp {
    position: absolute;
    top: 6vh;
    top: calc(6 * var(--vh, 1vh));

    width: 100%;

    text-align: center;

    @include vp--767 {
      top: unset;
      bottom: 15vh;
      bottom: calc(15 * var(--vh, 1vh));
    }

    @media screen and (max-height: 400px) {
      bottom: 20vh;
      bottom: calc(20*var(--vh, 1vh));
    }
  }

  .btn-down {
    position: absolute;

    pointer-events: unset;
    opacity: 1;
  }

  .decor-el {
    z-index: 10;
    transition: top $trans--2000, right $trans--2000, bottom $trans--2000, left $trans--2000;
    will-change: top, bottom, left, right;
  }

  .decor-el--1 {
    right: unset;
    left: 36vw;
    top: 32vh;
    top: calc(32 * var(--vh, 1vh));

    @include vp--767 {
      right: unset;
      z-index: 10;
      left: 20px;
      bottom: 24vh;
      bottom: calc(24*var(--vh, 1vh));
      top: unset;
    }
  }

  .decor-el--2 {
    left: unset;
    right: 38.5vw;
    top: 31vh;
    top: calc(21 * var(--vh, 1vh));

    @include vp--767 {
      display: block;
      right: 7vw;
      top: 23vh;
      top: calc(23 * var(--vh, 1vh));
    }
  }

  .decor-el--3 {
    bottom: unset;
    left: unset;
    right: 34vw;
    top: 42.3vh;
    top: calc(42.3 * var(--vh, 1vh));

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--4 {
    bottom: unset;
    right: unset;
    left: 49vw;
    top: 49vh;
    top: calc(49 * var(--vh, 1vh));
    z-index: 10;

    @include vp--767 {
      display: none;
    }
  }

  .decor-el--5 {
    top: unset;
    left: 36vw;
    bottom: 20.5vh;
    bottom: calc(20.5 * var(--vh, 1vh));

    @include vp--767 {
      left: unset;
      right: 10px;
      bottom: 16vh;
      bottom: calc(16 * var(--vh, 1vh));
    }
  }

  .decor-el--6 {
    bottom: 26vh;
    bottom: calc(26 * var(--vh, 1vh));
    right: 40vw;

    @include vp--767 {
      bottom: 33vh;
      bottom: calc(33*var(--vh, 1vh));
      right: 19vw;
      z-index: 10;
    }
  }
}

.scroll-content--taste.is-anim {
  .scroll-content__page-bg {
    opacity: 1;

    &::before {
      opacity: 1;
    }
  }
}

.page {
  position: relative;
  width: 100vw;
  height: 100vh;

  @include vp--767 {
    width: auto;
    height: auto;
  }

  &__bg,
  &__bg-last {
    will-change: opacity;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(129.5deg,$color-summer-sky 25.85%,$color-fun-blue 100%);
    transition: opacity $trans--1000;

    &::before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background-image: url("../img/bg/still-bg.png");
      background-size: size(324px) size(324px);

      @include retina {
        background-image: url("../img/bg/still-bg@2x.png");
      }

      @include vp--767 {
        background-size: 150px;
      }
    }

    @include vp--767 {
      position: absolute;

      &::before {
        position: absolute;
      }
    }
  }

  &__bg-last {
    background: linear-gradient(129.5deg,$color-broom 25.85%,$color-apple 100%);
    opacity: 0;

    &::before {
      background-image: url("../img/bg/lemon-bg.png");

      @include retina {
        background-image: url("../img/bg/lemon-bg@2x.png");
      }
    }
  }

  &__title-wrp {
    position: fixed;

    margin: size(65px) auto 0;

    width: 100%;

    text-align: center;

    opacity: 0;
    transition: opacity $trans--1000;
  }

  &__title {
    margin: 0 0 size(15px) 0;
    width: auto;

    text-align: center;
    font-family: $font-family--gotham;
    font-style: italic;
    font-weight: 900;
    font-size: size(60px);
    line-height: 1;
    text-transform: uppercase;

    @include vp--767 {
      margin-bottom: 5px;

      font-size: 22px;
    }
  }

  &__sub-title {
    margin: 0;

    text-transform: uppercase;
    text-align: center;
    font-family: $font-family--helvetica;
    font-weight: 500;
    font-size: size(18px);
    line-height: 1.3;
    letter-spacing: 0.3em;

    @include vp--767 {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__boutle {
    will-change: left, transform, top;
    position: fixed;
    z-index: 1;
    left: 33.6vw;
    top: size(145px);

    display: flex;
    align-items: center;

    width: 30.7vw;
    height: 72.8vh;
    height: calc(72.8 * var(--vh, 1vh));

    // pointer-events: none;
    cursor: pointer;
    transform: scale(0.85);
    transition: transform $trans--1500, left $trans--1500, top $trans--1000;
    -webkit-transform: translate3d(0,0,0);

    img,
    picture {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 767px) and (min-height: 900px) and (max-width: 1050px) {
      top: 12vh;
      top: calc(12 * var(--vh, 1vh));
      left: 21.5vw;
      width: 52vw;
    }

    @include vp--767 {
      position: absolute;

      left: 2.1vw;
      top: 14.8vh;
      top: calc(14.8*var(--vh, 1vh));

      width: 91vw;
      height: 71.8vh;
      height: calc(71.8*var(--vh, 1vh));

      transform: translateY(0);
      transition: transform $trans--1500, left $trans--1500, top $trans--1000, opacity $trans--1000;
    }
  }

  .decor-el {
    position: absolute;
    width: 15vw;
    height: 15vw;
    max-width: 250px;
    max-height: 250px;
    pointer-events: none;

    @include vp--767 {
      width: 120px;
      height: 120px;
    }

    &--1 {
      top: 14vh;
      top: calc(14 * var(--vh, 1vh));
      right: 25vw;

      @include vp--767 {
        right: 5vw;
      }
    }

    &--2 {
      top: 26vh;
      top: calc(26 * var(--vh, 1vh));
      left: 23vw;

      @include vp--767 {
        display: none;
      }
    }

    &--3 {
      bottom: 5vh;
      bottom: calc(5 * var(--vh, 1vh));
      left: 20.7vw;

      @include vp--767 {
        left: -1.7vw;
        bottom: 13vh;
        bottom: calc(13 * var(--vh, 1vh));
      }
    }

    &--4 {
      bottom: 5vh;
      bottom: calc(5 * var(--vh, 1vh));
      right: 20vw;

      transition: bottom $trans--1000;

      @include vp--767 {
        display: none;
      }
    }
  }

  .btn-back,
  .btn-down {
    z-index: 5;
  }

  .btn-down {
    opacity: 0;
    pointer-events: none;

    @include vp--767 {
      transition: opacity $trans--300;
    }
  }
}

.page.loaded {
  .page__boutle {
    transform: scale(1);
  }
}

.page[data-scroll-screen="1"],
.page[data-scroll-screen="2"],
.page[data-scroll-screen="3"],
.page[data-scroll-screen="4"] {
  .page__boutle {
    transform: scale(1.12);

    @include vp--767 {
      transform: inherit;
    }
  }
}

.page[data-scroll-screen="0"] {
  .page__title-wrp {
    opacity: 1;

    @include vp--767 {
      opacity: 0;
    }
  }

  .btn-down {
    opacity: 1;
    pointer-events: unset;
  }

  .page__boutle {
    @include vp--767 {
      transform: translateY(0);
    }
  }
}

.page[data-scroll-screen="1"] {
  .scroll-content--main {
    .decor-el--4 {
      bottom: -9vh;
      bottom: calc(-9 * var(--vh, 1vh));
    }
  }
}

//переходящая льдинка
.page.page--slider[data-scroll-screen="2"] {
  .scroll-content--main {
    .decor-el--4 {
      transform: translateY(0);
    }
  }
}

//вжух с переворотом бутылки
.page--slider[data-scroll-screen="3"],
.page--no-slider[data-scroll-screen="2"] {
  .page__boutle {
    transform: scale(1.32) rotate(90deg) translateY(17.6vw) translateX(-3.9vh);
    @media screen and (min-height: 750px) and (max-height: 1300px) and (min-width: 1023px) and (max-width: 1320px) {
      transform: scale(1.32) rotate(90deg) translateY(17.6vw) translateX(2.9vh);
    }

    @media screen and (min-width: 767px) and (min-height: 900px) and (max-width: 1050px) {
      transform: scale(0.87) rotate(90deg) translateY(21.6vw) translateX(-2.9vh);
    }
  }

  .scroll-content__boutle-bg {
    transform: scale(1) translate(0, 0);
  }
}


.page--slider[data-scroll-screen="4"],
.page--no-slider[data-scroll-screen="3"] {
  .page__bg {
    opacity: 0;
  }

  .page__bg-last {
    opacity: 1;
  }

  .page__boutle {
    transform: scale(1.32) translateY(-100vh) translateX(-50%);

    @include vp--767 {
      transform: inherit;
    }
  }

  .scroll-content--taste {
    .decor-el--1 {
      right: unset;
      left: 26vw;
      top: 22vh;
      top: calc(22 * var(--vh, 1vh));

      @include vp--767 {
        z-index: 10;
        left: -5px;
        bottom: 20vh;
        bottom: calc(20*var(--vh, 1vh));
        top: unset;
      }
    }

    .decor-el--2 {
      left: unset;
      right: 28.5vw;
      top: 21vh;
      top: calc(21 * var(--vh, 1vh));

      @include vp--767 {
        display: block;
        right: 4vw;
        top: 19vh;
        top: calc(19 * var(--vh, 1vh));
      }
    }

    .decor-el--3 {
      bottom: unset;
      left: unset;
      right: 24vw;
      top: 42.3vh;
      top: calc(42.3 * var(--vh, 1vh));
    }

    .decor-el--4 {
      bottom: unset;
      right: unset;
      left: 39vw;
      top: 49vh;
      top: calc(49 * var(--vh, 1vh));
      z-index: 10;

      @include vp--1023 {
        left: 24vw;
      }
    }

    .decor-el--5 {
      top: unset;
      left: 26vw;
      bottom: 10.5vh;
      bottom: calc(10.5 * var(--vh, 1vh));

      @include vp--767 {
        left: unset;
        right: -21px;
        bottom: 14vh;
        bottom: calc(14 * var(--vh, 1vh));
      }
    }

    .decor-el--6 {
      bottom: 16vh;
      bottom: calc(16 * var(--vh, 1vh));
      right: 30vw;

      @include vp--767 {
        bottom: 30vh;
        bottom: calc(30*var(--vh, 1vh));
        right: 16vw;
        z-index: 10;
      }
    }
  }
}

// лимон - мята

.page--lemon {
  .page__bg {
    background: linear-gradient(129.5deg,$color-broom 25.85%,$color-apple 100%);

    &::before {
      background-image: url("../img/bg/lemon-bg.png");

      @include retina {
        background-image: url("../img/bg/lemon-bg@2x.png");
      }
    }
  }

  .page__bg-last {
    background: linear-gradient(129.5deg,$color-cranberry 25.85%,$color-valencia 100%);

    &::before {
      background-image: url("../img/bg/raspberries-bg.png");

      @include retina {
        background-image: url("../img/bg/raspberries-bg@2x.png");
      }
    }
  }

  .scroll-content--quality {
    .description-block {
      top: 25.5vh;
      top: calc(25.5*var(--vh, 1vh));

      .advantages {
        margin-top: size(53px);

        @include vp--767 {
          margin-top: 15px;
        }
      }

      @include vp--1439 {
        .description-block__title {
          br {
            display: none;
          }
        }
      }
    }
  }
}

// малина мята

.page--raspberry {
  .page__bg {
    background: linear-gradient(129.5deg, $color-cranberry 25.85%, $color-valencia 100%);

    &::before {
      background-image: url("../img/bg/raspberries-bg.png");

      @include retina {
        background-image: url("../img/bg/raspberries-bg@2x.png");
      }
    }
  }

  .page__bg-last {
    background: linear-gradient(129.5deg, $color-persian-red 25.85%, $color-mandarian-orange 100%);

    &::before {
      background-image: url("../img/bg/soda-bg.png");

      @include retina {
        background-image: url("../img/bg/soda-bg@2x.png");
      }
    }
  }

  .scroll-content--description {
    .description-block.left {
      max-width: calc(50vw - 454px);

      @include vp--1023 {
        max-width: 30vw;
      }

      @include vp--767 {
        max-width: 100%;
      }
    }
  }

  .scroll-content--quality {
    .description-block {
      top: 25.5vh;
      top: calc(25.5*var(--vh, 1vh));

      .advantages {
        margin-top: size(53px);

        @include vp--767 {
          margin-top: 15px;
        }
      }

      @include vp--1439 {
        .description-block__title {
          br {
            display: none;
          }
        }
      }
    }
  }

  .scroll-content--taste {
    .scroll-content__splash {
      background-image: url('../img/content/splash-soda.png');

      @include retina {
        background-image: url("../img/content/splash-soda@2x.png");
      }
    }
  }
}

// газированная

.page--sparkling {
  .page__bg {
    background: linear-gradient(129.5deg, $color-persian-red 25.85%, $color-mandarian-orange 100%);

    &::before {
      background-image: url("../img/bg/soda-bg.png");

      @include retina {
        background-image: url("../img/bg/soda-bg@2x.png");
      }
    }
  }

  .page__bg-last {
    background: linear-gradient(129.5deg,$color-summer-sky 25.85%,$color-fun-blue 100%);

    &::before {
      background-image: url("../img/bg/still-bg.png");

      @include retina {
        background-image: url("../img/bg/still-bg@2x.png");
      }
    }
  }
}

// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: italic;
  font-weight: 900;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gotham-pro-black-italic.woff2") format("woff2"),
    url("../fonts/gotham-pro-black-italic.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gotham-pro-bold.woff2") format("woff2"),
    url("../fonts/gotham-pro-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gotham-pro.woff2") format("woff2"),
    url("../fonts/gotham-pro.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Helvetica Nue Cyr";

  font-display: swap;
  src:
    url("../fonts/helvetica-neue-cyr-medium.woff2") format("woff2"),
    url("../fonts/helvetica-neue-cyr-medium.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 700;
  font-family: "Helvetica Nue Cyr";

  font-display: swap;
  src:
    url("../fonts/helvetica-neue-cyr-bold-italic.woff2") format("woff2"),
    url("../fonts/helvetica-neue-cyr-bold-italic.woff") format("woff");
}

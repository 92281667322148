.advantages {
  margin: 0;

  display: flex;
  flex-wrap: wrap;

  width: calc(100% + 2.8125rem*3);
  padding: 0 20px 0 0;

  list-style: none;

  @include vp--1439 {
    width: calc(100% + 36px);
  }

  @include vp--767 {
    flex-direction: column;
  }

  &__item {
    width: size(250px);
    margin-right: size(45px);
    margin-bottom: size(45px);

    @include vp--1439 {
      margin-right: 12px;
      width: calc(100%/3 - 12px);
    }

    @include vp--767 {
      margin-bottom: 14px;

      padding-right: 20px;

      align-items: center;
      display: flex;

      width: 100%;
    }
  }

  &__text {
    margin: 13px 0 0;

    font-family: $font-family--helvetica;
    font-weight: 700;
    font-style: italic;
    font-size: size(26px);
    line-height: 1;

    @include vp--767 {
      margin: 0 0 12px;

      font-size: 18px;

      padding-left: 20px;
    }
  }

  &__icon {
    svg {
      fill: none;

      width: size(86px);
      height: size(86px);

      @include vp--767 {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.main-slider {
  z-index: 2;
  //width: 100%;
  height: 100%;
  overflow: visible;

  width: 50%;

  opacity: 0;

  @include vp--1439 {
    width: 60%;
  }

  @include vp--767 {
    width: 66%;
  }

  .animate-load & {
    animation-name: fade-in;
    animation-delay: 1s;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .animate-load-end & {
    opacity: 1;
  }
}

.main-slider__slide-inner {
  position: absolute;
  top: 0;
  right: 27%;
  bottom: 0;
  left: 27%;

  @include vp--1439 {
    right: 20%;
    left: 20%;
  }

  @include vp--767 {
    right: 20%;
    left: 20%;
  }
}

.main-slider--disabled {
  & ~ .slider-btn {
    opacity: 0.5;
    pointer-events: none;
  }
}

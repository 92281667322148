// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";

// Global
//---------------------------------

@import "functions";
@import "variables";
@import "mixins";
@import "keyframes";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
//---------------------------------

@import "blocks/preloader";
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/btn";
@import "blocks/main-page";
//@import "blocks/modal";
@import "blocks/page-title";
@import "blocks/main-slider";
@import "blocks/slider-btn";
@import "blocks/tastes";
@import "blocks/tastes-title";
@import "blocks/taste";
@import "blocks/description-block";
@import "blocks/redirect-screen";
@import "blocks/page";

@import "blocks/scroll-content";
@import "blocks/advantages";



// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */

.page-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  flex-shrink: 0;
}

.page-title__main {
  margin-bottom: size(14px);
  font-family: $font-family--gotham;
  font-style: italic;
  font-weight: 900;
  font-size: size(60px);
  line-height: 1;
  overflow: hidden;
  padding-left: size(10px);
  padding-right: size(10px);

  span {
    will-change: transform;
    display: block;
    transform: translate3d(0, 100%, 0);
  }

  .animate-load & span {
    animation-name: grow-up;
    animation-duration: 1s;
    animation-delay: 1.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    //@include vp--767 {
    //  animation-delay: 0.8s;
    //  animation-duration: 0.6s;
    //}
  }

  .animate-load-end & span {
    transform: none;
  }

  //@include vp--1439 {
  //  font-size: 52px;
  //  padding-left: 10px;
  //  padding-right: 10px;
  //}
  //
  //@include vp--1279 {
  //  font-size: 42px;
  //}

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      margin-bottom: 14px;
      font-size: 52px;
    }
  }

  @include vp--1023 {
    font-size: 42px;
  }


  @include vp--767 {
    margin-bottom: 12px;
    font-size: 23px;
  }

  [data-device="mobile landscape"] & {
    margin-bottom: 8px;
  }
}

.page-title__sub {
  will-change: transform, opacity;
  font-family: $font-family--helvetica;
  font-weight: 500;
  font-size: size(18px);
  line-height: 1.3;
  letter-spacing: 0.3em;

  opacity: 0;
  transform: translate3d(0, 300%, 0);

  .animate-load & {
    animation-name: fade-in-bottom-step;
    animation-duration: 1s;
    animation-delay: 1.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    //@include vp--767 {
    //  animation-delay: 0.8s;
    //  animation-duration: 0.6s;
    //}
  }

  .animate-load-end & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  //@include vp--1919 {
  //  font-size: 16px;
  //}
  //
  //@include vp--1279 {
  //  font-size: 15px;
  //}

  @media (min-width: $vp--1024) {
    [data-device="tablet portrait"] & {
      font-size: 16px;
    }
  }

  @include vp--1023 {
    font-size: 15px;
  }

  @include vp--767 {
    max-width: 250px;
    font-size: 12px;
  }

  [data-device="mobile landscape"] & {
    max-width: none;
  }
}


